import { Card, Stack } from "react-bootstrap";
import { RSS_FEED_URL } from '../Constants';
import { useState, useEffect } from "react";

function PropertyMiniView({ address, owner, size, price, incentives }) {

    return (
        <>
            <Card className='property-card demo-property-card'>
                <Card.Body>
                    <Stack direction='horizontal' gap={3} style={{ height: '47px' }}>
                        <Card.Title style={{ width: '70%' }}>{address}</Card.Title>
                        <Card.Title>{price}</Card.Title>
                    </Stack>
                    <Card.Text>{owner} | {size} Sq. Ft.</Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}

export default PropertyMiniView;