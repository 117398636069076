import { useEffect, useState, useRef } from 'react';
import { API_BASE_URL, IMAGE_BASE_URL } from '../Constants';
import { Button, Card, Col, Form, Row, Stack } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import PropertyInfo from './PropertyInfo';



function Properties({address}) {
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedCounty, setSelectedCounty] = useState("");
    const [allProperties, setAllProperties] = useState([]);
    const [availableProperties, setAvailableProperties] = useState([]);
    const [spaceTypes, setSpaceTypes] = useState([]);
    const [cities, setCities] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [center, setCenter] = useState({ lat: 26.709723, lng: -80.064163 });
    const [displayInfo, setDisplayInfo] = useState(false)
    const [selectedSpaceType, setSelectedSpaceType] = useState(null)
    const [selectedProperty, setSelectedProperty] = useState({})

    const [sortedProperties, setSortedProperties] = useState([]);


    const [isLiveRFP, setIsLiveRFP] = useState(false)
    const [isUpcomingRFP, setIsUpcomingRFP] = useState(false)
    const [surplus, setSurplus] = useState(false)

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyA-wTYWpVt5bu7JWdDD2m_-Rh7zb-xNPCU",
    });


    useEffect(() => {
        if (address[0]) {
            setSelectedCity(address[0])
            if (cities.length == 0) {
                getAvailableCities(address[1]);
            }
        }

        if (address[1]) {
            setSelectedCounty(address[1])
            getAvailableProperties("", address[0], address[1])
        }

    }, [address]);

    useEffect(() => {
        updateSearchResults()
    }, [isLiveRFP, isUpcomingRFP, surplus]);

    function getAvailableCities(county) {
        fetch(`${API_BASE_URL}properties?include=city,county,id&filter=county,eq,${county}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        `This is an HTTP error: The status is ${response.status}`
                    );
                }
                return response.json();
            })
            .then((response) => {
                let counties = [];
                let cities = [];
                let citiesGroupedByCounty = {};
                response.records.forEach(item => {
                    if (cities.includes(item.city) == false) {
                        cities.push(item.city);
                    }
                });
                setCities(cities);
            })
            .catch((err) => {

            });
    }

    function getAvailableProperties(filters = "", city, county) {
        let url = `${API_BASE_URL}properties?filter=county,cs,${county}`;
        if (city) {
            url = url + `&filter=city,cs,${city}`;
        }
        url = url + filters;
        url = url + `&order=is_surplus_land,desc`;
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        `This is an HTTP error: The status is ${response.status}`
                    );
                }
                return response.json();
            })
            .then((response) => {
                setAllProperties(response.records);
                const splicedProperties = response.records.slice(0,50);
                setAvailableProperties(splicedProperties);
                
                let centeredMap = false
                splicedProperties.forEach(item => {
                    if (item.latitude && !centeredMap) {
                        setCenter({ lat: item.latitude, lng: item.longitude });
                        centeredMap = true;
                    }
                })

                let spaceTypes = [];
                response.records.forEach(item => {
                    if (spaceTypes.includes(item.predominant_space_type) == false) {
                        spaceTypes.push(item.predominant_space_type);
                    }

                    let size = parseInt(item.sq_ft)
                    if (sizes.includes(size) == false) {
                        sizes.push(size);
                    }
                });

                

                setSizes(sizes)
                setSpaceTypes(spaceTypes);
            })
            .catch((err) => {

            });
    }

    function filterAvailableProperties(spaceType) {
        if (spaceType == "") {
            setAvailableProperties(allProperties);
        } else {
            let properties = [];
            allProperties.forEach(property => {
                if (property.predominant_space_type == spaceType) {
                    properties.push(property);
                }
            });
            setAvailableProperties(properties);
        }
    }

    function displayPropertyOverlay(index) {
        const sortedProperties = availableProperties
        const property = sortedProperties.splice(index, 1)[0];
        sortedProperties.unshift(property);
        setSortedProperties(sortedProperties)
        setDisplayInfo(true)
    }

    function onFilterChange(type) {
        if (type == "liveRFP") {
            setIsLiveRFP(!isLiveRFP);
        }
        if (type == "upcomingRFP") {
            setIsUpcomingRFP(!isUpcomingRFP);
        }
        if (type == "surplus") {
            setSurplus(!surplus);
        }
    }

    function updateSearchResults() {
        var filters = "";

        if (isLiveRFP) {
            filters = filters + `&filters1=rfp_status,eq,live`;
        }
        if (isUpcomingRFP) {
            filters = filters + `&filters1=rfp_status,eq,upcoming`;
        }
        if (surplus) {
            filters = filters + `&filters1=is_surplus_land,eq,1`;
        }
        getAvailableProperties(filters, selectedCity, selectedCounty);
    }

    return (
        <>
            <div className="container-fluid vh-100" style={{ backgroundColor: '#F2F2F2' }}>
                <Stack direction="horizontal" gap={3} className="col-md-7 mt-2 pt-2">
                    <Form.Select style={{ width: '500px' }} size="lg" onChange={(e) => { setSelectedSpaceType(e.target.value); filterAvailableProperties(e.target.value); }}>
                        <option value="">--Select Space Type --</option>
                        {spaceTypes && spaceTypes.map((spaceType, index) => (
                            <option key={index}>{spaceType}</option>
                        ))}
                    </Form.Select>
                    <Form.Select size="lg" onChange={(e) => { setSelectedCity(e.target.value) }} value={selectedCity}>
                        <option value="">--Select City --</option>
                        {cities && cities.map((city, index) => (
                            <option key={index}>{city}</option>
                        ))}
                    </Form.Select>
                </Stack>
                <Stack direction="horizontal" className='mt-3' gap={4}>
                    <div className='col-md-9' style={{ width: '64vw', height: '90vh' }}>
                        {!isLoaded ? (
                            <h1>Loading...</h1>
                        ) : (
                            <GoogleMap
                                mapContainerClassName="map-container"
                                center={center}
                                zoom={12}
                                mapTypeId="hybrid"
                            >
                                {availableProperties.map((property, idx) => (
                                    <>
                                        {property.latitude &&
                                            <Marker key={property.id} position={{ lat: property.latitude, lng: property.longitude }} onClick={() => { displayPropertyOverlay(property) }} />
                                        }
                                    </>
                                ))}
                            </GoogleMap>
                        )}
                    </div>
                    <div className='col-sm-4' style={{ overflow: 'scroll', height: '90vh' }}>
                        <Stack>
                            <div className="mb-3">
                                <Form.Check
                                    inline
                                    label="Live RFP"
                                    onChange={() => { onFilterChange("liveRFP") }}
                                    name="property-filters"
                                />
                                <Form.Check
                                    inline
                                    label="Surplus"
                                    onChange={() => { onFilterChange("surplus") }}
                                    name="property-filters"
                                />
                                <Form.Check
                                    inline
                                    label="Upcoming RFP"
                                    onChange={() => { onFilterChange("upcomingRFP") }}
                                    name="property-filters"
                                />
                            </div>
                        </Stack>
                        <Row xs={1} sm={1} className="g-4 col-sm-8">
                            {availableProperties.map((property, idx) => (
                                <Col key={idx} onClick={(e) => { displayPropertyOverlay(idx) }} role="button">
                                    <Card className='property-card'>
                                        <Card.Body>
                                            <Stack direction='horizontal' gap={3} style={{ height: '47px' }}>
                                                <Card.Title style={{ width: '70%' }}>{property.address}</Card.Title>
                                                <Card.Title>$1,505,380</Card.Title>
                                            </Stack>
                                            <Card.Text>{property.managing_agency} | {property.sq_ft.toLocaleString('en-US')} Sq. Ft.</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Stack>
            </div>

            <PropertyInfo displayInfo={displayInfo} properties={sortedProperties} onHide={() => { setDisplayInfo(false); }} />
        </>
    );
}

export default Properties;