import { useEffect, useState, useRef } from 'react';
import { API_BASE_URL } from '../Constants';
import { Button, Form, Image, Stack } from 'react-bootstrap';
import FeedCard from '../shared/FeedCard';
import { useNavigate } from 'react-router-dom';
import demoImage from '../assets/images/demo-map.png'
import demoIcentiveTab from '../assets/images/demo-incentive-tab.png'
import PropertyMiniView from '../shared/PropertyMiniView';
import PatnershipBox from '../shared/PatnershipBox';
import NewsCard from '../shared/NewsCard';
import Footer from '../shared/Footer';


function Landing() {
    const [availableCities, setAvailableCities] = useState([]);
    const [citiesGroupedByCounty, setCitiesGroupedByCounty] = useState({});
    const [cities, setCities] = useState([]);
    const [counties, setCounties] = useState([]);

    const [selectedCounty, setSelectedCounty] = useState("")
    const [selectedCity, setSelectedCity] = useState("")
    const [selectedAddress, setSelectedAddress] = useState("")


    let navigate = useNavigate();

    const inputRef = useRef()
    if (window.google) {
        const autoComplete = new window.google.maps.places.Autocomplete(
            inputRef.current,
        )


        autoComplete.addListener('place_changed', () => {
            const place = autoComplete.getPlace()
            if (place.address_components) {
                let addressComponents = place.address_components
                let county = "";
                let city = "";
                if (addressComponents.length == 3) {
                    county = addressComponents[0];
                } else if (addressComponents.length == 4) {
                    city = addressComponents[0];
                    county = addressComponents[1];
                } else if (addressComponents.length > 4) {
                    city = addressComponents[1];
                    county = addressComponents[2];
                }

                setSelectedCity(city.short_name);
                setSelectedCounty(county.short_name.replace("County", "").trim());
                setSelectedAddress(place.formatted_address)
            }
        });
    }

    useEffect(() => {
        getAvailableCountiesAndCities()
    }, []);

    function getAvailableCountiesAndCities() {
        fetch(`${API_BASE_URL}properties?include=city,county,id`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        `This is an HTTP error: The status is ${response.status}`
                    );
                }
                return response.json();
            })
            .then((response) => {
                let counties = [];
                let cities = [];
                let citiesGroupedByCounty = {};
                response.records.forEach(item => {
                    if (cities.includes(item.city) == false) {
                        cities.push(item.city);
                    }
                    if (counties.includes(item.county) == false) {
                        counties.push(item.county);
                    }

                    if (!citiesGroupedByCounty[item.county]) {
                        citiesGroupedByCounty[item.county] = [];
                    }

                    var citiesList = citiesGroupedByCounty[item.county];
                    if (citiesList.includes(item.city) == false) {
                        citiesList.push(item.city);
                    }
                    citiesGroupedByCounty[item.county] = citiesList
                });
                setCities(cities);
                setCounties(counties);

                setAvailableCities(cities);
                setCitiesGroupedByCounty(citiesGroupedByCounty);
            })
            .catch((err) => {

            });
    }

    function filterCities(county) {
        setSelectedCounty(county)
        if (county.length == 0) {
            setCities(availableCities);
        } else {
            setCities(citiesGroupedByCounty[county]);
        }
    }

    function navigateResults() {
        if (selectedCity) {
            navigate(`properties/${selectedCounty}/${selectedCity}/${selectedAddress}`);
        } else {
            navigate(`properties/${selectedCounty}/${selectedAddress}`)
        }
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            let address = event.target.value;
            let county = address.split(',')[0].replace("County", "").trim();
            navigate(`properties/${county}/${address}`)
        }
    }

    return (
        <>
            <div className="container-fluid vh-100 justify-content-center align-content-center" id="landing-screen">
                <Stack>
                    <p className="mx-auto landing-heading">Creating private and public partnerships</p>
                    <p className="mx-auto landing-subheading">Help build our future communities</p>
                    <Stack direction="horizontal" gap={3} className="mx-auto mt-1 pt-5">
                        <Form.Control className='search-input-field' ref={inputRef} size="lg" type="text" placeholder="State, County, City" onKeyDown={handleKeyPress} />
                        <Button size="lg" className='app-button-primary' id='search-button' onClick={() => { navigateResults() }}>Search</Button>
                    </Stack>
                </Stack>
            </div>
            <div className="container-fluid pb-5 justify-content-center align-content-center" style={{ background: '#F2F2F2' }}>
                <Stack className='pt-5'>
                    <p className="mx-auto section-heading">Explore available properties</p>
                    <p className="mx-auto section-subheading mt-0 pt-0">Government owned, Live RFPs, many with incentives</p>
                    <Stack direction="horizontal" gap={3} className="mx-auto mt-5 pt-5">
                        <Image src={demoImage} height={409} rounded />
                        <Stack gap={3} className='mt-3 ms-5'>
                            <PropertyMiniView
                                address="1254 Palm Tree Lane, Fort Lauderdale, FL 33301"
                                price="$1,505,380"
                                owner="Owner Name"
                                size="40,000"
                            />
                            <PropertyMiniView
                                address="8742 Oceanview Drive, Hollywood, FL 33020"
                                price="$1,270,001"
                                owner="Owner Name"
                                size="10,000"
                            />
                            <PropertyMiniView
                                address="1254 Palm Tree Lane, Fort Lauderdale, FL 33301"
                                price="$3,750,800"
                                owner="Owner Name"
                                size="24,000"
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </div>
            <div className="container-fluid justify-content-center align-content-center" style={{ background: '#FFF' }}>
                <Stack className='pt-5 pb-3'>
                    <p className="mx-auto section-heading">Find incentives right for you</p>
                    <p className="mx-auto section-subheading mt-0 pt-0">Subheading about incentives</p>
                    <Stack direction="horizontal" gap={3} className="mx-auto pt-5">
                        <Image src={demoIcentiveTab} width={963} height={598} rounded />
                        <Stack gap={3} className='mt-3 ms-5'>
                            <p className='landing-incentive-heading' style={{ marginBottom: 49 }}>Oh yes Incentives!</p>
                            <div style={{ width: 450 }}>
                                <p className='landing-incentive-bullets'>1. Great fact about incentives</p>
                                <p className='landing-incentive-bullets'>2. Another great fact you definitely didn’t know</p>
                                <p className='landing-incentive-bullets' style={{ marginBottom: 75 }}>3. The most interesting fact there is to know in the world of facts</p>
                            </div>
                            <Button className='browse-incentives-btn' onClick={() => navigate('properties/byld')}>Browse Incentives</Button>
                        </Stack>
                    </Stack>
                </Stack>
            </div>
            <div className="container-fluid pb-5 justify-content-center align-content-center" style={{ background: '#F2F2F2' }}>
                <Stack className='pt-5'>
                    <p className="mx-auto section-heading" style={{ marginTop: 20 }}>Exclusive Partnerships</p>
                    <Stack gap={3} className="mx-auto mt-5 pt-5">
                        <Stack direction='horizontal' gap={5}>
                            <PatnershipBox
                                name="Housing Authority"
                                speciality="Specializing in Senior Housing"
                                propertyCount="109"
                            />
                            <PatnershipBox
                                name="Commissioner"
                                speciality="Surplus land in Broward"
                                propertyCount="92"
                            />
                        </Stack>
                        <Stack direction='horizontal' gap={5}>
                            <PatnershipBox
                                name="Roofers Union"
                                speciality="Roof on yo head"
                                propertyCount="117"
                            />
                            <PatnershipBox
                                name="Ghostbusters"
                                speciality="Specializing in Entity Removal"
                                propertyCount="42"
                            />
                        </Stack>
                        <Button className='browse-partnership-btn mt-5' onClick={() => navigate('properties/byld')} style={{ width: 555 }}>Browse Partnerships</Button>
                    </Stack>
                </Stack>
            </div>

            <div className="container-fluid pb-3 justify-content-center align-content-center" style={{ background: '#FFF' }}>
                <Stack className='pt-5'>
                    <p className="mx-auto section-heading">BYLD Daily News</p>
                    <Stack direction="horizontal" gap={3} className="mx-auto mt-5 pt-5">
                        <NewsCard
                            title="Modernization Magnet or Money Pit?"
                            subtitle="Broward Debates Billion-Dollar Construction Spree"
                            image="https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/03ddd6b1-85d2-49bc-b8c6-a81bb8308e07/Ft-Lauderdale-FL.jpg"
                        />
                        <NewsCard
                            title="Carter Park Community Center Development"
                            subtitle="Fort Lauderdale's Untapped Diamond: Investing in the Carter Park Community Center's Brilliance"
                            image="https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/1c4f59c9-d93f-403c-ae10-25ab3a7de78e/Carter_Park.jpeg"
                        />
                        <NewsCard
                            title="New River Crossing Project Update"
                            subtitle="Navigating Tunnels, Bridges, and the Quest for Quiet: The New River Crossing Project at a Crossroads"
                            image="https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/8fc76e0d-815b-44b9-9d51-28c371fac625/Fort-Lauderdale-Riverwalk-1500x609.jpg"
                        />
                    </Stack>
                    <Button className='browse-partnership-btn mt-5' onClick={() => navigate('properties/byld')} style={{ width: 555 }}>See the latest stories</Button>
                </Stack>
            </div>
            <div className="container-fluid justify-content-center align-content-center newsletter-section" style={{ background: '#E8E8E8' }}>
                <p className='newsletter-heading'>Stay up to date</p>
                <p className='newsletter-subheading'>Follow our newsletter</p>
                <Form.Control className='email-input-field' size="lg" type="text" placeholder="Enter your email" />
                <Button className='signup-btn mt-5' style={{ display: 'block' }}>Sign up</Button>
            </div>
            <Footer />
        </>
    );
}

export default Landing;