import { Button, Form, Stack } from "react-bootstrap"
import PatnerView from "../shared/PatnerView"

function PatnershipList() {

    return (
        <>
            <div className="container-fluid vh-100" style={{ backgroundColor: '#F2F2F2' }}>
                <Stack gap={3} style={{ width: '60%', margin: '0 auto' }}>
                    <Stack direction="horizontal" className="mt-3">
                        <Form.Control className='incentive-search-input-field me-3 mt-3' size="lg" type="text" placeholder="Enter keyword" />
                        <Button size="lg" className='app-button-secondary mt-3' onClick={() => { }}>Search</Button>
                    </Stack>
                    <Stack direction="horizontal" gap={3}>
                        <Form.Select className="incentive-filters" size="md" onChange={(e) => { }}>
                            <option value="">Partner Type</option>
                        </Form.Select>
                        <Form.Select className="incentive-filters" size="md" onChange={(e) => { }}>
                            <option value="">Offer</option>
                        </Form.Select>
                    </Stack>
                    <Stack direction="horizontal" className="mt-3">
                        <Form.Check
                            inline
                            label="Land"
                            name="group1"
                        />
                        <Form.Check
                            inline
                            label="Money"
                            name="group1"
                        />
                        <Form.Check
                            className="me-auto"
                            label="Grants"
                        />
                        <p className="mt-3">1 - 100 of 1,093</p>
                    </Stack>

                    <PatnerView title="Owner - 50 acres - Manatee County" partnershipType={"Seeking partner to develop land"} unitCount={30} />
                    <PatnerView title="Non profit Grant secured - Manatee County" partnershipType={"Seeking developer partner in affordable housing"} unitCount={50} />
                    <PatnerView title="Investor - Cash - Manatee County" partnershipType={"Seeking investment opportunities in real estate "} unitCount={100} />
                </Stack>
            </div>
        </>
    )
}

export default PatnershipList