import { Card, Stack } from "react-bootstrap";
import { RSS_FEED_URL } from '../Constants';
import { useState, useEffect } from "react";

function NewsCard({ title, subtitle, image }) {

    return (
        <>
            <Card className='news-card'>
                <Card.Img variant="top" src={image} width={480} height={300} />
                <Card.Body style={{height: 155 , backgroundColor: '#EAEAEA'}}>
                    <Card.Title className="news-title">{title}</Card.Title>
                    <Card.Text className="news-subtitle">{subtitle}</Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}

export default NewsCard;