import { Card, Stack } from "react-bootstrap";
import { RSS_FEED_URL } from '../Constants';
import { useState, useEffect } from "react";

function PatnerView({ title, unitCount, partnershipType }) {

    return (
        <>
            <Card className='incentive-card'>
                <Card.Body>
                    <Stack direction="horizontal">
                        <Stack>
                            <Card.Title className="incentive-card-title">{title}</Card.Title>
                            <Card.Text className="incentive-card-heading1">{partnershipType}</Card.Text>
                            {/*<Card.Text className="incentive-card-subheading">Overview | Department | Upcoming Workshops | Latest News</Card.Text>*/}
                        </Stack>
                        <Stack className="text-end">
                            <Card.Text className="incentive-card-unitCount">Min {unitCount} Units</Card.Text>
                            <Card.Text className="incentive-card-links">Contact | Application</Card.Text>
                        </Stack>
                    </Stack>
                </Card.Body>
            </Card>
        </>
    )
}

export default PatnerView;