import { useEffect, useMemo, useState } from 'react';
import { API_BASE_URL, IMAGE_BASE_URL } from '../Constants';
import { Button, Card, Carousel, Col, Form, Image, Nav, Row, Stack } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import Modal from 'react-bootstrap/Modal';
import socialIcon from '../assets/images/social-icons.png'


function Footer() {
    useEffect(() => {
    }, []);


    return (
        <>
            <div className="container-fluid footer-section" style={{ background: '#D9D9D9' }}>
                <Stack direction='horizontal' className='footer-row'>
                    <Stack>
                        <p className='footer-heading'>BYLD</p>
                        <p className='footer-subheading'>Real estate development</p>
                    </Stack>
                    <Stack direction='horizontal' gap={5} className="mb-5">
                        <Stack>
                            <p className='footer-section-heading'>WHAT WE DO</p>
                            <p className='footer-section-subheading'>Property Search</p>
                            <p className='footer-section-subheading'>Incentives</p>
                            <p className='footer-section-subheading'>Partnerships</p>
                            <p className='footer-section-subheading'>BYLD Daily News</p>
                        </Stack>
                        <Stack>
                            <p className='footer-section-heading'>CONTACT</p>
                            <p className='footer-section-subheading'>1-800-123-4567</p>
                            <p className='footer-section-subheading'>Hello@tryBYLD.com</p>
                            <Image src={socialIcon} height={30} width={105} rounded />
                        </Stack>
                    </Stack>
                </Stack>
            </div>
        </>
    );
}

export default Footer;