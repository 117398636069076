import logo from './logo.svg';
import './App.css';

import React, { useState, useEffect } from 'react';
import Landing from './pages/Landing';
import SearchResults from './pages/SearchResults';
import Layout from './Layout';
import { Route, Routes } from 'react-router-dom';


function App() {
    useEffect(() => {
        document.title = 'BYLD - Real Estate Development Connections';
    }, []);
  return (
    <>
      <Routes>
        {/* Since we do not need header in landing screen. */}
        <Route path="/" element={<Layout />}>
          <Route index element={<Landing />} />
          <Route path="properties/:county/:address" element={<SearchResults />} />
          <Route path="properties/:county/:city/:address" element={<SearchResults />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
