import { Card, Stack } from "react-bootstrap";
import { RSS_FEED_URL } from '../Constants';
import { useState, useEffect } from "react";

function PatnershipBox({ name, speciality, propertyCount }) {

    return (
        <>
            <Card className='patnership-card'>
                <Card.Body>
                    <Card.Title className="patnership-card-name">{name}</Card.Title>
                    <Card.Text className="patnership-card-speciality">{speciality}</Card.Text>
                    <Card.Text className="patnership-card-count">{propertyCount} Properties</Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}

export default PatnershipBox;