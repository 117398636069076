import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import heartIcon from '../assets/images/heart-icon.png'
import accountIcon from '../assets/images/account-icon.png'
import logo from '../assets/images/logo.png'
import { Image } from 'react-bootstrap';

function TopNavigationBar() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" id='main-nav-bar'>
      <Navbar.Brand href="/" className='ms-5'><Image src={logo} /></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
        </Nav>
        <Nav>
          <Nav.Link className='nav-links'>
            <Image src={heartIcon} height={32} rounded />
          </Nav.Link>
          <Nav.Link className='nav-links me-5'>
            <Image src={accountIcon} height={32} rounded />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default TopNavigationBar;