import { useEffect, useState, useRef } from 'react';
import { API_BASE_URL, IMAGE_BASE_URL } from '../Constants';
import { Button, Card, Col, Form, Row, Stack } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import PropertyInfo from './PropertyInfo';
import Properties from './Properties';
import IncentiviesList from './IncentiviesList';
import PatnershipList from './PatnershipList';
import NewsList from './NewsList';



function SearchResults() {
    let { city, county, address } = useParams();
    const [selectedCity, setSelectedCity] = useState(city);
    const [allProperties, setAllProperties] = useState([]);
    const [availableProperties, setAvailableProperties] = useState([]);
    const [spaceTypes, setSpaceTypes] = useState([]);
    const [cities, setCities] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [center, setCenter] = useState({ lat: 26.709723, lng: -80.064163 });
    const [displayInfo, setDisplayInfo] = useState(false)
    const [selectedSpaceType, setSelectedSpaceType] = useState(null)
    const [selectedProperty, setSelectedProperty] = useState({})

    const [sortedProperties, setSortedProperties] = useState([]);

    const [selectedCounty, setSelectedCounty] = useState(county)

    const [selectedAddress, setSelectedAddress] = useState([city, county]);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyA-wTYWpVt5bu7JWdDD2m_-Rh7zb-xNPCU",
    });

    const [selectedTab, setSelectedTab] = useState("properties")

    const inputRef = useRef()
    if (window.google) {
        const autoComplete = new window.google.maps.places.Autocomplete(
            inputRef.current,
        )


        autoComplete.addListener('place_changed', () => {
            const place = autoComplete.getPlace()
            if (place.address_components) {
                let addressComponents = place.address_components
                let county = "";
                let city = "";
                if (addressComponents.length == 3) {
                    county = addressComponents[0];
                } else if (addressComponents.length == 4) {
                    city = addressComponents[0];
                    county = addressComponents[1];
                } else if (addressComponents.length > 4) {
                    city = addressComponents[1];
                    county = addressComponents[2];
                }

                setSelectedCity(city.short_name);
                setSelectedCounty(county.short_name.replace("County", "").trim());

                setSelectedAddress([city.short_name, county.short_name.replace("County", "").trim()])
            }
        });
    }


    return (
        <>
            <div className="container-fluid">
                <Form.Control className='property-list-search-input-field mt-3' ref={inputRef} defaultValue={`${address}`} size="lg" type="text" placeholder="State, County, City" />
                <ul class="nav nav-pills nav-fill mt-3 mb-3" id="app-tabs">
                    <li className="nav-item">
                        <a className={"nav-link " + (selectedTab == "properties" ? "active": "")} aria-current="page" href="#" onClick={() => { setSelectedTab("properties"); return true }}>Properties</a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link " + (selectedTab == "incentives" ? "active": "")} href="#" onClick={() => { setSelectedTab("incentives"); return true }}>Incentives</a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link " + (selectedTab == "partnerships" ? "active": "")} href="#" onClick={() => { setSelectedTab("partnerships"); return true }}>Partnerships</a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link " + (selectedTab == "news" ? "active": "")} href="#" onClick={() => { setSelectedTab("news"); return true }}>BYLD News</a>
                    </li>
                </ul>
            </div>
            {selectedTab == "properties" &&
                <Properties address={selectedAddress} />
            }

            {selectedTab == "incentives" &&
                <IncentiviesList />
            }

            {selectedTab == "partnerships" &&
                <PatnershipList />
            }

            {selectedTab == "news" &&
                <NewsList />
            }
        </>
    );
}

export default SearchResults;