import { Card, Stack } from "react-bootstrap";
import { RSS_FEED_URL } from '../Constants';
import { useState, useEffect } from "react";

function OwnerView({ name, subtitle, description }) {

    return (
        <>
            <Card className='owner-card'>
                <Card.Body>
                    <Stack direction="horizontal">
                        <Stack>
                            <Card.Title className="owner-card-title">{name}</Card.Title>
                            <Card.Text className="owner-card-heading1">{subtitle}</Card.Text>
                            <Card.Text className="owner-card-heading1">{description}</Card.Text>
                        </Stack>
                        <Stack className="text-end">
                            <Card.Text className="owner-card-links">Contact</Card.Text>
                        </Stack>
                    </Stack>
                </Card.Body>
            </Card>
        </>
    )
}

export default OwnerView;