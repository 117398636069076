import { Button, Form, Stack } from "react-bootstrap"
import { useEffect, useState } from 'react';
import NewsCard from "../shared/NewsCard"

function NewsList() {
    const [searchTerm, setSearchTerm] = useState("")
    const [allNewsList, setAllNewsList] = useState([
        {
            "title": "Modernization Magnet or Money Pit?",
            "subtitle": "Broward Debates Billion-Dollar Construction Spree",
            "image": "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/03ddd6b1-85d2-49bc-b8c6-a81bb8308e07/Ft-Lauderdale-FL.jpg"
        },
        {
            "title": "Carter Park Community Center Development",
            "subtitle": "Fort Lauderdale's Untapped Diamond: Investing in the Carter Park Community Center's Brilliance",
            "image": "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/1c4f59c9-d93f-403c-ae10-25ab3a7de78e/Carter_Park.jpeg"
        },
        {
            "title": "New River Crossing Project Update",
            "subtitle": "Navigating Tunnels, Bridges, and the Quest for Quiet: The New River Crossing Project at a Crossroads",
            "image": "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/8fc76e0d-815b-44b9-9d51-28c371fac625/Fort-Lauderdale-Riverwalk-1500x609.jpg"
        },
        {
            "title": "Modernization Magnet or Money Pit?",
            "subtitle": "Broward Debates Billion-Dollar Construction Spree",
            "image": "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/03ddd6b1-85d2-49bc-b8c6-a81bb8308e07/Ft-Lauderdale-FL.jpg"
        },
        {
            "title": "Carter Park Community Center Development",
            "subtitle": "Fort Lauderdale's Untapped Diamond: Investing in the Carter Park Community Center's Brilliance",
            "image": "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/1c4f59c9-d93f-403c-ae10-25ab3a7de78e/Carter_Park.jpeg"
        },
        {
            "title": "New River Crossing Project Update",
            "subtitle": "Navigating Tunnels, Bridges, and the Quest for Quiet: The New River Crossing Project at a Crossroads",
            "image": "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/8fc76e0d-815b-44b9-9d51-28c371fac625/Fort-Lauderdale-Riverwalk-1500x609.jpg"
        }
    ]);

    const [newsList, setNewsList] = useState([
        [
            {
                "title": "Modernization Magnet or Money Pit?",
                "subtitle": "Broward Debates Billion-Dollar Construction Spree",
                "image": "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/03ddd6b1-85d2-49bc-b8c6-a81bb8308e07/Ft-Lauderdale-FL.jpg"
            },
            {
                "title": "Carter Park Community Center Development",
                "subtitle": "Fort Lauderdale's Untapped Diamond: Investing in the Carter Park Community Center's Brilliance",
                "image": "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/1c4f59c9-d93f-403c-ae10-25ab3a7de78e/Carter_Park.jpeg"
            },
            {
                "title": "New River Crossing Project Update",
                "subtitle": "Navigating Tunnels, Bridges, and the Quest for Quiet: The New River Crossing Project at a Crossroads",
                "image": "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/8fc76e0d-815b-44b9-9d51-28c371fac625/Fort-Lauderdale-Riverwalk-1500x609.jpg"
            }
        ],
        [
            {
                "title": "Modernization Magnet or Money Pit?",
                "subtitle": "Broward Debates Billion-Dollar Construction Spree",
                "image": "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/03ddd6b1-85d2-49bc-b8c6-a81bb8308e07/Ft-Lauderdale-FL.jpg"
            },
            {
                "title": "Carter Park Community Center Development",
                "subtitle": "Fort Lauderdale's Untapped Diamond: Investing in the Carter Park Community Center's Brilliance",
                "image": "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/1c4f59c9-d93f-403c-ae10-25ab3a7de78e/Carter_Park.jpeg"
            },
            {
                "title": "New River Crossing Project Update",
                "subtitle": "Navigating Tunnels, Bridges, and the Quest for Quiet: The New River Crossing Project at a Crossroads",
                "image": "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/8fc76e0d-815b-44b9-9d51-28c371fac625/Fort-Lauderdale-Riverwalk-1500x609.jpg"
            }
        ]
    ]);

    function filterLists(searchTerm) {
        let term = searchTerm.toLowerCase();
        if (term.length > 0) {
            let items = []
            allNewsList.forEach(item => {
                if (item.title.toLowerCase().includes(term) || item.subtitle.toLowerCase().includes(term)) {
                    items.push(item)
                }
            })
            setNewsList(chunkArray(items, 3))
        } else {
            setNewsList(allNewsList)
        }
    }

    function chunkArray(array, chunkSize) {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    }

    return (
        <>
            <div className="container-fluid" style={{ backgroundColor: '#F2F2F2' }}>
                <Stack gap={3} style={{ width: '90%', margin: '0 auto' }}>
                    <Stack direction="horizontal" className="mt-3">
                        <Form.Control className='incentive-search-input-field me-3 mt-3' defaultValue={searchTerm} onKeyDown={(e) => { if (e.key === 'Enter') { filterLists(searchTerm) } }} onChange={(e) => { setSearchTerm(e.target.value) }} size="lg" type="text" placeholder="Enter keyword" />
                        <Button size="lg" className='app-button-secondary mt-3' onClick={() => { filterLists(searchTerm) }}>Search</Button>
                    </Stack>
                    <Stack direction="horizontal" gap={3}>
                        {/*
                         <Form.Select className="incentive-filters" size="md" onChange={(e) => { }}>
                            <option value="">Project Type</option>
                        </Form.Select>
                        <Form.Select className="incentive-filters" size="md" onChange={(e) => { }}>
                            <option value="">Incentive Type</option>
                        </Form.Select>
                        <Form.Select className="incentive-filters" size="md" onChange={(e) => { }}>
                            <option value="">Incentive Owner</option>
                        </Form.Select>
                        <Form.Select className="incentive-filters" size="md" onChange={(e) => { }}>
                            <option value="">Unit Count</option>
                        </Form.Select>
                        */}
                    </Stack>
                    <Stack direction="horizontal" className="mt-3" gap={3}>
                        <p className="mt-3 ms-auto">1 - 20 of 83</p>
                    </Stack>
                    {newsList && newsList.map((rows, index) => (
                        <Stack direction="horizontal" key={`news-row-${index}`} gap={3}>
                            {rows && rows.map((item, index) => (
                                <NewsCard
                                    key={`news-card-${index}`}
                                    title={item.title}
                                    subtitle={item.subtitle}
                                    image={item.image}
                                />
                            ))}
                        </Stack>
                    ))}
                </Stack>
            </div>
        </>
    )
}

export default NewsList