import { useEffect, useMemo, useState } from 'react';
import { API_BASE_URL, IMAGE_BASE_URL } from '../Constants';
import { Button, Card, Carousel, Col, Form, Image, Nav, Row, Stack } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import Modal from 'react-bootstrap/Modal';
import IncentiviesList from './IncentiviesList';
import IncentiveView from '../shared/IncentiveView';
import OwnerView from '../shared/OwnerView';




function PropertyInfo({ properties, displayInfo, onHide }) {
    useEffect(() => {
    }, []);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <>
            <Modal
                id="property-info-modal"
                size="lg"
                show={displayInfo}
                onHide={onHide}
            >
                <Modal.Header closeButton>
                    <Nav className='info-tabs'>
                        <Nav.Link id="property-nav-item" onClick={() => scrollToSection('property')}>Property</Nav.Link>
                        <Nav.Link id="incentives-nav-item" onClick={() => scrollToSection('incentives')}>Incentives</Nav.Link>
                        <Nav.Link id="owner-nav-item" onClick={() => scrollToSection('owner')}>Owner</Nav.Link>
                        <Nav.Link id="application-nav-item" onClick={() => scrollToSection('application')}>Application</Nav.Link>
                    </Nav>
                </Modal.Header>
                <Modal.Body>
                    <Carousel id="property-carousel" variant="dark" indicators={false} interval={null}>
                        {properties && properties.map((property, idx) => (
                            <Carousel.Item>
                                <Stack>
                                    {property &&
                                        <Image src={`${IMAGE_BASE_URL}static-maps-image/${property.static_map_image}`} style={{ height: '512px', width: '100%' }} />
                                    }
                                    <Stack direction='vertical'>
                                        <Stack direction='horizontal' className='mt-3 ms-5'>
                                            <Stack id="property">
                                                <p className="fw-bold address mb-0">{property.address}</p>
                                                <p className="fw-normal">{property.city}</p>
                                            </Stack>
                                            <p className="fw-bold price me-3">$1,505,380</p>
                                        </Stack>
                                        <Stack direction='horizontal' gap={3} className='mt-3 ms-5 metadata-row'>
                                            <div className='property-info-box'>
                                                <p>{property.predominant_space_type || "-- property type"}</p>
                                            </div>
                                            <div className='property-info-box'>
                                                <p>{property.sq_ft} Sq Ft</p>
                                            </div>
                                            <div className='property-info-box'>
                                                <p>-- sqft lot</p>
                                            </div>
                                        </Stack>
                                        <Stack direction='horizontal' gap={3} className='mt-3 ms-5 metadata-row'>
                                            <div className='property-info-box'>
                                                <p>Built in {property.built_year || "--" }</p>
                                            </div>
                                            <div className='property-info-box' style={{ opacity: 0 }}>
                                                <p>Built in {property.built_year || "--" }</p>
                                            </div>
                                            <div className='property-info-box' style={{ opacity: 0 }}>
                                                <p>Built in {property.built_year || "--" }</p>
                                            </div>
                                        </Stack>
                                        <p style={{ height: '150px' }}></p>
                                        <h3 id="incentives" className="mb-2 ms-5">Incentives</h3>
                                        <Stack gap={2} className='property-incentives'>
                                            <IncentiveView title=" Manatee County Affordable Housing Incentive Program" unitCount={50} />
                                            <IncentiveView title="SAIL Financing" unitCount={30} />
                                            <IncentiveView title="Low Income Housing Tax Credit" unitCount={50} />
                                        </Stack>
                                        <p style={{ height: '150px' }}></p>
                                        <h3 id="owner" className="mb-2 ms-5">Owner</h3>
                                        <div className='owner-holder'>
                                            <OwnerView
                                                name={"John Smith"}
                                                subtitle={"Placeholder subtitle about the Owner"}
                                                description={"Placeholder subtitle about the Owner. Just some random words to help show content that can be here and how it looks"}
                                            />
                                        </div>
                                        <p style={{ height: '150px' }}></p>
                                        <h3 id="application" className="fw-bold mb-0 mt-3 ms-5">Application</h3>
                                        <Stack direction='horizontal' gap={1} style={{ width: '60%'}}>
                                            <p className="mt-3 ms-5 property-info-box"><a style={{ color: "#000000", textDecoration:'none', margin: 'auto' }} href="https://www.mymanatee.org/departments/property_management/property_acquisition/surplus_property/invitation_to_negotiate" target="_blank">Application</a></p>
                                            <p className="mt-3 ms-5 property-info-box mx-auto"><p>Download Files</p></p>
                                        </Stack>
                                        <p style={{ height: '150px' }}></p>
                                    </Stack>
                                </Stack>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PropertyInfo;