import { Outlet } from "react-router-dom";
import TopNavigationBar from './navigation/TopNavigationBar';

function Layout() {
    return (
        <>
            <TopNavigationBar />
            <Outlet />
        </>
    )
}

export default Layout;