import { Button, Form, Stack } from "react-bootstrap"
import IncentiveView from "../shared/IncentiveView"

function IncentiviesList() {

    return (
        <>
            <div className="container-fluid vh-100" style={{ backgroundColor: '#F2F2F2' }}>
                <Stack gap={3} style={{ width: '60%', margin: '0 auto' }}>
                    <Stack direction="horizontal" className="mt-3">
                        <Form.Control className='incentive-search-input-field me-3 mt-3' size="lg" type="text" placeholder="Enter keyword" />
                        <Button size="lg" className='app-button-secondary mt-3' onClick={() => { }}>Search</Button>
                    </Stack>
                    <Stack direction="horizontal" gap={3}>
                        <Form.Select className="incentive-filters" size="md" onChange={(e) => { }}>
                            <option value="">Project Type</option>
                        </Form.Select>
                        <Form.Select className="incentive-filters" size="md" onChange={(e) => { }}>
                            <option value="">Date</option>
                        </Form.Select>
                        <Form.Select className="incentive-filters" size="md" onChange={(e) => { }}>
                            <option value="">Unit Count</option>
                            <option value="">1-10 units</option>
                            <option value="">10-50 units</option>
                            <option value="">50-100 units</option>
                            <option value="">100+ units</option>
                        </Form.Select>
                    </Stack>
                    <Stack direction="horizontal" className="mt-3">
                        <Form.Check
                            inline
                            label="Federal"
                        />
                        <Form.Check
                            inline
                            label="State"
                        />
                        <Form.Check
                            inline
                            label="Local"
                        />
                        <Form.Check
                            className="me-auto"
                            inline
                            label="Private"
                        />
                        <p className="mt-3">1 - 100 of 1,093</p>
                    </Stack>

                    <IncentiveView title=" Manatee County Affordable Housing Incentive Program" unitCount={50} />
                    <IncentiveView title="SAIL Financing" unitCount={30} />
                    <IncentiveView title="Low Income Housing Tax Credit" unitCount={50} />
                    <IncentiveView title="HUD 221(d)(4) Loans" unitCount={100} />
                </Stack>
            </div>
        </>
    )
}

export default IncentiviesList