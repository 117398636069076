// constants.js
// export const API_BASE_URL = 'http://localhost:8888/realestatedev/api.php/records/';
// export const IMAGE_BASE_URL = 'http://localhost:8888/realestatedev/';
// export const RSS_FEED_URL = 'http://localhost:8888/realestatedev/feed.php?';
// export const MAX_ITEMS_PER_PAGE = 10;


export const API_BASE_URL = 'https://devinternalrealestate.com/api.php/records/';
export const IMAGE_BASE_URL = 'https://devinternalrealestate.com/';
export const RSS_FEED_URL = 'https://devinternalrealestate.com/feed.php?';
export const MAX_ITEMS_PER_PAGE = 10;
